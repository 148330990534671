
































































import { defineComponent, PropType } from "@vue/composition-api"
import ParsedMissionFieldTable from "./ParsedMissionFieldTable.vue"
import ParsedMissionSignFieldTable from "./ParsedMissionSignFieldTable.vue"
import { ParsedMissionTemplate } from "@/utilities/Parser"
import ParsedErrorSpan from "@/components/ParsedErrorSpan.vue"
import { missionTypeFormatter } from "@/services/MissionsService"

export default defineComponent({
    name: "MissionFieldTable",
    components: {
        ParsedErrorSpan,
        ParsedMissionFieldTable,
        ParsedMissionSignFieldTable,
    },
    props: {
        value: {
            required: true,
            type: Array as PropType<ParsedMissionTemplate[]>,
        },
    },
    setup() {
        const headers = [
            {
                text: "列",
                sortable: false,
            },
            {
                text: "ID",
                sortable: false,
            },
            {
                text: "類型",
                sortable: false,
            },
            {
                text: "主旨",
                sortable: false,
            },
            {
                text: "描述",
                sortable: false,
            },
            {
                text: "時長（分鐘）",
                sortable: false,
            },
            {
                text: "管理人員",
                sortable: false,
            },
            {
                text: "受指派人員",
                sortable: false,
            },
            {
                text: "協作人員",
                sortable: false,
            },
        ]
        return {
            headers,
            missionTypeFormatter,
        }
    },
})
